import React, { useEffect, useState, useRef } from "react";
import { graphql, Link } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Container from "../components/Container/Container";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/SEO/SEO";
import Image from "gatsby-plugin-sanity-image";
import InView from "../components/InView";
import { useMediaQuery } from "react-responsive";
import useParallaxBlock from "../lib/parallaxBlock";

export const query = graphql`
  query ProjectPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      defaultImage {
        asset {
          _id
        }
      }
    }
    sanityAwards(_id: { regex: "/^(?!drafts.).*$/" }) {
      title
      description
      _id
      hero {
        kind
        heroImage {
          ...Image
          alt
        }
        heroVideoUrl
      }
    }
    projects: allSanityProject(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          previewImage {
            ...Image
            alt
          }
          title
          slug {
            current
          }
          projectType {
            id
            title
          }
          content {
            ... on SanityGlance {
              _key
              _type
              _rawAwards
            }
          }
        }
      }
    }
    categories: allSanityProjectType {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

const Awards = (props) => {
  const { data, errors } = props;
  const site = (data || {}).site;
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [hideProjects, setHideProjects] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const topRef = useRef();

  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
        .filter(filterOutDocsWithoutSlugs)
        .map((node) => {
          console.log("Project Node:", node);
          return node;
        })
        .filter((project) =>
          project.content.some(
            (content) => content._rawAwards && content._rawAwards.length > 0
          )
        )
    : [];

  const categoryNodes =
    data && data.categories ? mapEdgesToNodes(data.categories) : [];

  // Extract category IDs from projects. Handle both single and multiple categories per project.
  const projectCategoryIds = projectNodes
    .flatMap((project) =>
      project.projectType ? project.projectType.map((type) => type.id) : []
    )
    .filter((id, index, array) => id && array.indexOf(id) === index);

  const filteredCategoryNodes = categoryNodes.filter((category) =>
    projectCategoryIds.includes(category.id)
  );

  useEffect(() => {
    setProjects(projectNodes);
  }, []);

  function filterProjects(filter) {
    setSelectedFilter(filter);
    setHideProjects(true);
    setTimeout(() => {
      if (filter === null || filter === "all") {
        setProjects(projectNodes);
      } else {
        setProjects(
          projectNodes.filter((el) =>
            el.projectType.map((el) => el.id).includes(filter)
          )
        );
      }
      setHideProjects(false);
    }, 600);
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ maxWidth: 1023 });
    return isNotMobile ? children : null;
  };

	const parallaxA = useParallaxBlock((scale) => {
		return 0.5 + scale * 1.8
	});

	const parallaxB = useParallaxBlock((scale) => {
		return 1.2 - scale * 1.5;
	});

  function ProjectListing(props) {
    return (
      <>
        <Default>
          <div className="col-1">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 2 === 0)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-2">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 2 === 1)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
        </Default>
        <Desktop>
          <div
            className="col-1"
            ref={parallaxB.ref}
          >
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 0)
                .map((node) => (
                  <div
                    className="mb-6 md:mb-10 md:pl-10 overflow-hidden"
                    key={node.id}
                  >
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-2">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 1)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div
            className="col-3"
            ref={parallaxA.ref}
          >
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 2)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
        </Desktop>
      </>
    );
  }

  ProjectListing.defaultProps = {
    title: "",
    nodes: [],
  };

  function ProjectPreview(props) {
    const renderAwards = (awards) => {
      return awards.map((award) => (
        <p key={award._key}>
          {award.children.map((child) => child.text).join(" ")}
        </p>
      ));
    };
    return (
      <>
        <Link className="group" to={`/project/${props.slug.current}`}>
          <div className="block overflow-hidden relative">
            {props.previewImage && (
              <Image
                {...props.previewImage}
                width={600}
                alt={props.previewImage.alt}
                className={
                  `transition-transform duration-500 ease-in-out md:group-hover:transform md:group-hover:scale-[1.05] lazy ` +
                  (loaded && " loaded")
                }
                onLoad={() => setLoaded(true)}
              />
            )}
          </div>
          <div className="flex items-end justify-between gap-4 my-6">
            <h2 className="text-[20px] md:text-6 leading-[32px] font-serif">
              {props.title}
              {props.content._rawAwards}
            </h2>
            <span className="flex flex-none gap-2 items-center text-[14px] font-medium">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
              >
                <path
                  stroke="#4D4D4D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="0.5"
                  strokeWidth="1.5"
                  d="M3.333 8h9.333m0 0L8 3.335m4.666 4.667L8 12.667"
                ></path>
              </svg>
              View project
            </span>
          </div>
        </Link>
        <div>
          <h3 className="text-[13px] text-[#969696]">Awards and Nominations</h3>
          {props.content &&
            props.content.some((c) => c._type === "glance" && c._rawAwards) && (
              <div className="text-[13px] leading-[24px] text-[#000] mt-1">
                {renderAwards(
                  props.content.find((c) => c._type === "glance")._rawAwards
                )}
              </div>
            )}
        </div>
      </>
    );
  }

  function DesktopCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <li className="my-px" key={node.id}>
              <DesktopCategory {...node} />
            </li>
          ))}
      </>
    );
  }

  DesktopCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  };

  function scrollTo() {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function DesktopCategory(props) {
    return (
      <button
        onClick={() => {
          filterProjects(props.id);
          scrollTo();
        }}
        className={
          selectedFilter === props.id
            ? "border-b border-b-black"
            : "border-b border-b-transparent text-grey-default transition-all ease-in-out duration-300 hover:text-black hover:border-b hover:border-black"
        }
      >
        {props.title}
      </button>
    );
  }

  function MobileCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <option key={node.id} value={node.id}>
              {node.title}
            </option>
          ))}
      </>
    );
  }

  MobileCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  };

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  return (
    <>
      <Seo
        title={data.sanityAwards.title}
        description={data.sanityAwards.description}
        image={
          data.sanityAwards.hero.heroImage
            ? data.sanityAwards.hero.heroImage
            : site.defaultImage
        }
      />
      <Container>
        <div className="border-b border-grey-default box-border">
          <div className="grid grid-cols-1 md:grid-cols-2 bg-off-white mb-4 md:mb-0">
            <div className="px-6 py-10 md:pl-10 md:my-[120px] md:mr-[120px] flex flex-col order-2 md:order-1">
              <div className="md:my-auto text-[14px] leading-[24px] max-w-[400px]">
                <h2>Awards</h2>
                <h1 className="font-serif text-[40px] leading-[1.4] md:text-[56px] md:leading-[72px] my-6 md:my-10">
                  {data.sanityAwards.title}
                </h1>
                <div className="text-[#666]">
                  {data.sanityAwards.description}
                </div>
              </div>
            </div>
            {data.sanityAwards.hero.kind === "image" && (
              <div className="order-1 md:order-2">
                <Image
                  {...data.sanityAwards.hero.heroImage}
                  width={880}
                  height={880}
                  alt={data.sanityAwards.hero.heroImage.alt}
                  className={
                    `aspect-[390/305] md:aspect-[720/610] object-cover object-center h-full lazy ` +
                    (loaded && " loaded")
                  }
                  onLoad={() => setLoaded(true)}
                />
              </div>
            )}
            {/* 
            {data.sanityOurProjects.hero.kind === "video" && (
              <div
                className={
                  `hero-wrapper video-wrapper lazy ` + (loaded && " loaded")
                }
              >
                <ReactPlayer
                  width="100vw"
                  height="100vh"
                  playing
                  playsinline={true}
                  loop
                  muted
                  url={data.sanityOurProjects.hero.heroVideoUrl}
                  onStart={() => setLoaded(true)}
                />
              </div>
            )} */}
          </div>

          <InView>
            <div className="relative pb-10 md:pb-0">
              <div className="container relative flex flex-wrap flex-start md:pt-[120px] md:h-full md:min-h-screen">
                <div className="w-full pt-6 md:pt-0 md:w-1/4 md:sticky md:h-full md:top-[81px] md:pb-[25px] md:pr-10">
                  <h3 className="text-[16px] leading-[28px] mb-3 md:mb-6">
                    Filter by type
                  </h3>
                  <ul className="font-serif text-[24px] lg:text-[32px] leading-[1.2] hidden md:flex md:flex-col md:gap-4">
                    <li>
                      <button
                        onClick={() => {
                          filterProjects(null);
                          scrollTo();
                        }}
                        className={
                          selectedFilter === null
                            ? "border-b border-b-black"
                            : "border-b border-b-transparent text-grey-default transition-all ease-in-out duration-300 hover:text-black hover:border-b hover:border-black"
                        }
                      >
                        All
                      </button>
                    </li>
                    {filteredCategoryNodes && (
                      <DesktopCategoryListing nodes={filteredCategoryNodes} />
                    )}
                  </ul>
                  <select
                    className="md:hidden text-[14px] text-[#2e2e2e] text-opacity-50 w-full border p-3 border-grey-default box-border"
                    value={selectedFilter === null ? "all" : selectedFilter}
                    onChange={(event) => {
                      if (event.target.value === "all") {
                        filterProjects(null);
                      } else {
                        filterProjects(event.target.value);
                      }
                    }}
                  >
                    <option value="all">All</option>
                    {filteredCategoryNodes && (
                      <MobileCategoryListing nodes={filteredCategoryNodes} />
                    )}
                  </select>
                </div>
                <div className="mt-6 masonry--col-3 md:mt-0 w-full md:w-3/4 md:pb-[180px] relative md:border-l md:border-grey-default box-border">
                  <div
                    ref={topRef}
                    className="absolute top-[-100px] left-0"
                  ></div>
                  <div
                    className={`masonry grid gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-10 ${
                      hideProjects ? "hide" : ""
                    }`}
                  >
                    <ProjectListing nodes={projects} />
                  </div>
                </div>
              </div>
            </div>
          </InView>
        </div>
      </Container>
    </>
  );
};

export default Awards;
